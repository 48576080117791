import { useCallback, useEffect, useMemo, useState } from 'react';
import { Article } from 'types';
import { LandingBlockHeader } from '../BlockHeader';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Image from 'next/image'
import classnames from 'classnames';
import { formatDateTime } from 'helpers';
import { useTranslation } from 'next-i18next';
import CustomLink from 'components/CustomLink';

interface LatestUpdateArticlesProps {
  articles: Article[]
  isMobile: boolean
  isTablet: boolean
}

const ARTICLES_PAGE_SIZE = 3

export const LatestUpdateArticles: React.FC<LatestUpdateArticlesProps> = ({ articles, isMobile, isTablet }: LatestUpdateArticlesProps) => {
  const [articleIndex, setArticleIndex] = useState<number>(0)
  const [displayArticles, setDisplayArticles] = useState<Article[]>(articles.slice(articleIndex, articleIndex + ARTICLES_PAGE_SIZE))

  const { t } = useTranslation('common')
  const title = t('page.landing.latestUpdates.title')

  const getNextArticles = useCallback(() => {
    if (articleIndex + ARTICLES_PAGE_SIZE < articles.length) {
      setArticleIndex(articleIndex + ARTICLES_PAGE_SIZE)
    } else {
      setArticleIndex(0)
    }
  }, [articleIndex, articles])

  const getPrevArticles = useCallback(() => {
    if (articleIndex - ARTICLES_PAGE_SIZE >= 0) {
      setArticleIndex(articleIndex - ARTICLES_PAGE_SIZE)
    } else {
      setArticleIndex(articles.length - ARTICLES_PAGE_SIZE)
    }
  }, [articleIndex, articles])

  useEffect(() => {
    setDisplayArticles(articles.slice(articleIndex, articleIndex + ARTICLES_PAGE_SIZE))
  }, [articleIndex, articles])

  const renderTitle = useMemo(() => {
    return <LandingBlockHeader title={title} />
  }, [])

  const renderArticleList = useMemo(() => {
    const cnLinkWrapper = classnames('line-clamp-1', {
      'border-b border-divider-color-20 pb-6 last:border-b-0': isMobile
    })

    const cnContainer = classnames('flex-grow w-full ', {
      'min-w-[280px] max-w-[400px]': !isMobile,
    })

    const cnTitle = classnames('text-text-color-100 font-bold line-clamp-2 mb-1', {
      'text-lg': !isMobile,
      'text-base': isMobile,
    })

    const cnDescription = classnames('text-text-color-70 font-normal', {
      'text-base line-clamp-2': !isMobile,
      'text-sm line-clamp-3 max-h-[70px]': isMobile,
    })

    const renderDesktopArticle = (article: Article, index: number) => {
      const ARTICLE_READ_TIME_MIN = 5

      return (
        <CustomLink key={index} href={`/news/${article.slug}`}>
          <a className={cnLinkWrapper}>
            <div className={cnContainer}>
              <div className="text-text-color-60 text-xs mb-2">{formatDateTime(article.published_at)}</div>
              <div className="flex">
                <div>
                  <div className={cnTitle}>{article.title}</div>
                  <div className={cnDescription}>{article.description}</div>
                </div>
                <div>
                  <Image
                    src={article.image_url || '/images/empty.svg'}
                    alt={t('page.landing.latestUpdates.imageAlt', { title: article.title })}
                    height="67.5"
                    width="120"
                    className="w-full h-auto rounded-md"
                    layout="fixed"
                    objectFit="contain"
                    loading="eager"
                  />
                </div>
              </div>
              <div className="flex items-center mt-2 gap-5">
                <div className="text-xs font-semibold">
                  {t('page.landing.latestUpdates.minRead', { min: ARTICLE_READ_TIME_MIN })}
                </div>
              </div>
            </div>
          </a>
        </CustomLink>
      )
    }

    const renderMobileArticle = (article: Article, index: number) => {
      const ARTICLE_READ_TIME_MIN = 5

      return (
        <CustomLink key={index} href={`/news/${article.slug}`}>
          <a className={cnLinkWrapper}>
            <div className={cnContainer}>
              <div className="text-text-color-60 text-xs mb-2">{formatDateTime(article.published_at)}</div>
              <div className={cnTitle}>{article.title}</div>
              <div className="flex gap-2">
                <div className={cnDescription}>{article.description}</div>
                <div>
                  <Image
                    src={article.image_url || '/images/empty.svg'}
                    alt={t('page.landing.latestUpdates.imageAlt', { title: article.title })}
                    height="67.5"
                    width="120"
                    className="w-full h-auto rounded-md"
                    layout="fixed"
                    objectFit="contain"
                    loading="eager"
                  />
                </div>
              </div>
              <div className="flex items-center mt-2 gap-5">
                <div className="text-xs font-semibold text-text-color-60">
                  {t('page.landing.latestUpdates.minRead', { min: ARTICLE_READ_TIME_MIN })}
                </div>
              </div>
            </div>
          </a>
        </CustomLink>
      )
    }

    return displayArticles.map((article, index) => {
      return isMobile ? renderMobileArticle(article, index) : renderDesktopArticle(article, index)
    })
  }, [displayArticles, isMobile])

  const renderDesktop = useMemo(() => {
    const cnContainer = classnames('flex w-fit gap-10', {
      'mr-9': isTablet
    })

    return (
      <div className="w-full overflow-scroll no-scrollbar pl-0 pr-[6px] md:pr-3 lg:px-0">
        <div className={cnContainer}>
          {renderArticleList}
        </div>
      </div>
    )
  },[renderArticleList, isTablet])

  const renderMobile = useMemo(() => {
    return (
      <div className="flex flex-col w-fit gap-6">
        {renderArticleList}
      </div>
    )
  }, [renderArticleList])

  return (
    <div className="w-full mt-12 md:mt-9 mx-[18px] sm:mx-6 lg:mx-0">
      <div className="flex gap-7 mb-5 items-center">
        <div>{renderTitle}</div>
        <div className="flex items-center font-bold">
          <button className="border border-divider-color-40 rounded-l-md px-4 py-3" onClick={() => getPrevArticles()}><ChevronLeftIcon width={12} className="stroke-[3px]" /></button>
          <button className="border border-divider-color-40 rounded-r-md px-4 py-3 border-l-0" onClick={() => getNextArticles()}><ChevronRightIcon width={12} className="stroke-[3px]" /></button>
        </div>
      </div>
      {isMobile ? renderMobile : renderDesktop}
    </div>
  )
}