interface Event {
  type: string
  name?: string
  params?: any
}

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']

export const isUserPresentInDataLayer = (userId: string) => {
  const { dataLayer } = window
  let isInDataLayer = false

  if (dataLayer) {
    dataLayer.forEach(entry => {
      const isConfig = entry[0] === 'config' && entry[1].includes('G-')

      if (isConfig && entry[2]?.user_id === userId) {
        isInDataLayer = true
      }
    })
  }

  return isInDataLayer
}

const utmParams = (locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch)
  const resultingParams = {} as { [key: string]: string }

  searchParams.forEach((value, key) => {
    if (UTM_PARAMS.indexOf(key) !== -1) {
      resultingParams[key] = decodeURIComponent(value.replace(/\+/g, ' '))
    }
  })

  return resultingParams
}

const sendEvent = (event: Event['type'], name: Event['name'] = '', params: Event['params']) => {
  const { gtag } = window

  console.log(`Analytics: sendEvent<${event}, ${name}, ${JSON.stringify(params)}>`)

  if (!params) {
    if (gtag) {
      gtag(event, name)
    }
  } else {
    if (gtag) {
      gtag(event, name, params)
    }
  }
}

const sendUserProperties = (propertyName: string, propertyValue: string) => {
  const { gtag } = window

  console.log(`Analytics: sendUserProperties<${propertyName}, ${JSON.stringify(propertyValue)}>`)

  if (gtag) {
    gtag('set', 'user_properties', {
      [propertyName]: propertyValue,
    })
  }
}

const sendUserId = (userId: string) => {
  const { gtag, dataLayer } = window

  console.log(`Analytics: sendUserId<${userId}>`)

  if (!!gtag && !!dataLayer) {
    dataLayer.forEach(layer => {
      if (layer[0] === 'config' && layer[1].includes('G-')) {
        gtag('config', layer[1], {
          user_id: userId,
        })
      }
    })
  }
}

export const analytics = async (analyticEvent: Event) => {
  switch (analyticEvent.type) {
    case 'otherEvent':
      sendEvent('event', analyticEvent.name, analyticEvent.params)
      break

    case 'pageView':
      const { location } = window
      let defaultParams = {
        ...analyticEvent.params,
      }

      if (location) {
        defaultParams = {
          ...defaultParams,
          path: location.pathname,
          ...utmParams(location.search),
        }
      }

      sendEvent('event', analyticEvent.name, defaultParams)
      break

    case 'userProperties':
      sendUserProperties(analyticEvent.params.propertyName, analyticEvent.params.propertyValue)
      break

    case 'init':
      sendUserId(analyticEvent.params.userId)
      break

    default:
      break
  }
}
