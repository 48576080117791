import markdownToTxt from 'markdown-to-txt'

const slashesAndColons = /(\\)|(:::)|(==)/g
const newLines = /(\n)/g

export const convertPlainText = (content: string, shouldCutContent?: boolean, maxCharsToShow?: number): string => {
  const newContent = markdownToTxt(content || '')
    .toString()
    .replace(newLines, ' ')
    .replace(slashesAndColons, '')

  if (shouldCutContent) {
    return newContent.slice(0, maxCharsToShow)
  }

  return newContent
}
